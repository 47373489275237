//scripts
import header from './layout/header'
import footer from './layout/footer'
import linkNoOpener from './modules/linkNoOpener'
import agreeCheck from './modules/agreeCheck'
import innerLinkSummery from './modules/innerLink'
import innerLinkMovetoPage from './modules/innerLink_movetoPage'
import mailChange from './modules/mailChange'
import scrollbarWidth from './modules/scrollBarWidth'
import accordion from './modules/accordion'
import modalSummery from './modules/modal'
import sliderSummery from './modules/slider'
import scrollAnimation from './modules/scrollAnimation'
import scrollHint from './modules/scrollHint'
import mainVisualSlider from './modules/mainVisualSlider'
import tab from './modules/tab'
import bannerSlider from './modules/bannerSlider'
import popup from './modules/popup'
import print from './modules/print'
import routeMap from './modules/routeMap'

window.addEventListener('DOMContentLoaded', () => {
  header();
  footer();
  linkNoOpener();
  innerLinkSummery();
  innerLinkMovetoPage();
  agreeCheck();
  mailChange();
  scrollbarWidth();
  accordion();
  modalSummery();
  sliderSummery();
  scrollAnimation();
  scrollHint();
  mainVisualSlider();
  tab();
  bannerSlider();
  popup();
  print();
  routeMap();
})