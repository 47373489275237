import * as u from '../utility'
import {menuToggle} from './hamburgerToggle'

export default function languageSPToggle() {
  if (!document.querySelector('.js-nav-sp-language')) { return; }
  const lanBtn         = document.querySelector('.js-nav-sp-language')
  const lanMenuArea    = document.querySelector('.js-nav-sp-language-menu-area')
  const hamMenuArea    = document.querySelector('.js-nav-hamburger-menu-area')

  languageSPMenu(lanBtn, lanMenuArea, hamMenuArea)
}

export const languageSPMenu = (lanBtn, lanMenuArea, hamMenuArea) => {
  const hamBtn         = document.querySelector('.js-nav-hamburger')
  const hamBtnLabel    = hamBtn.querySelector('.label')
  //多言語選択メニュー
  lanBtn.addEventListener('click', () => {
    if (!lanBtn.classList.contains('is-active')) {//多言語を開くとき
      if (hamBtn.classList.contains('is-active')) {//ハンバーガーメニューが開いていたら
        menuToggle.close(hamBtn, hamMenuArea, hamBtnLabel)//ハンバーガーメニューを閉じる
      }
      lanMenuToggle.open(lanBtn, lanMenuArea, hamMenuArea)
    } else {
      lanMenuToggle.close(lanBtn, lanMenuArea, hamMenuArea)
    }
  })

  //閉じる
  const closeBtn = document.querySelector('.js-nav-sp-language-close')
  closeBtn.addEventListener('click', () => {
    lanMenuToggle.close(lanBtn, lanMenuArea, hamMenuArea)
  })

  //ブレイクポイント切り替えでメニューを非表示にする
  const mql = window.matchMedia( u.headerBreakPC );
  const breakpointChange = (e) => {
    if (e.matches) {//PCレイアウトになったら多言語選択メニューを閉じる
      lanMenuToggle.close(lanBtn, lanMenuArea, hamMenuArea)
    }
  }
  mql.addEventListener('change', breakpointChange);
}

export const lanMenuToggle = {
  open: (btn, menuArea, hamMenuArea) => {
    btn.classList.add('is-active')
    btn.setAttribute('aria-label', '多言語選択メニューを閉じる')
    menuArea.classList.add('is-show')
    hamMenuArea.classList.add('is-show')
    document.body.classList.add('is-non-scroll')
    document.querySelector('.l-header').classList.add('is-lan-menu-show')
  },
  close: (btn, menuArea, hamMenuArea) => {
    btn.classList.remove('is-active')
    btn.setAttribute('aria-label', '多言語選択メニューを開く')
    menuArea.classList.remove('is-show')
    hamMenuArea.classList.remove('is-show')
    document.body.classList.remove('is-non-scroll')
    document.querySelector('.l-header').classList.remove('is-lan-menu-show')
  }
}