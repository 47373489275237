import Swiper, { Autoplay, Pagination, Navigation, Manipulation, EffectFade } from '@/swiper';

// 呼び出しクラス一覧
export default function sliderSummery() {
  bgAutoplay('.js-slider-bg-autoplay')
  bgFadeOut('.js-slider-bg-fadeout')
  column3('.js-slider-column3')
  column4('.js-slider-column4')
}

// 案件ごとに調整したい場合は以下のエリアを触る
export const bgAutoplay = (jsClass) => {
  swiperAutoplaySlideshow(jsClass, 5000, true, false)
}

export const bgFadeOut = (jsClass) => {
  swiperFadeSlideshow(jsClass)
}

export const column3 = (jsClass) => {
  swiperAutoplaySlideshow(jsClass, 4000, true, true, {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      }
    },
  })
}

export const column4 = (jsClass) => {
  swiperAutoplaySlideshow(jsClass, 3000, false, true, {
    grabCursor: true,
    slidesPerView: 2,
    spaceBetween: 23,
    breakpoints: {
      768: {
        slidesPerView: 4,
        spaceBetween: 32,
      }
    },
  })
}
// ここまで：案件ごとに調整したい場合

// ここから下は基本的に触らない
/**
 * 横スクロールスライダー
 * @param {string | HTMLElement} jsClass クラス名
 * @param {number} delay スライドを表示する時間
 * @param {boolean} paginationActive ページネーションを表示するか
 * @param {boolean} navigationActive ナビゲーションを表示するか
 * @param {object} otherArgs その他設定項目
 * @returns
 */
export const swiperAutoplaySlideshow = (jsClass, delay = 5000, paginationActive = true, navigationActive = true, otherArgs = {}) => {
  const els = document.querySelectorAll(jsClass)
  if ((els?.length ?? 0) <= 0) { return; }

  const args = {
    modules: [Autoplay, Pagination, Navigation],
    loop: true,
    autoplay: {
      delay
    },
    grabCursor: true,
    slidesPerView: 1,
    ...otherArgs
  }

  els.forEach((el) => {
    if (paginationActive) {
      args['pagination'] = {
        el: el.querySelector('.swiper-pagination'),
        clickable: true
      }
    }

    if (navigationActive) {
      args['navigation'] = {
        prevEl: el.querySelector('.swiper-button-prev'),
        nextEl: el.querySelector('.swiper-button-next'),
      }
    }

    const swiper = new Swiper(el, args)

    // a11y対応時の一時停止ボタン実装用
    const swiperPlay = el.querySelector('.swiper-button-play')
    const swiperPause = el.querySelector('.swiper-button-pause')

    if (swiperPlay && swiperPause) {
      swiper.on('slideChange', () => {
        swiperPlay.disabled = true
        swiperPause.disabled = false
      })

      swiperPlay.addEventListener('click', () => {
        if (swiper.autoplay.paused) {
          swiperPlay.disabled = true
          swiperPause.disabled = false
          swiper.autoplay?.run()
        }
      })

      swiperPause.addEventListener('click', () => {
        if (!swiperSlider.autoplay.paused) {
          swiperPause.disabled = true
          swiperPlay.disabled = false
          swiper.autoplay.pause()
        }
      })
    }
  })
}

/**
 * フェードイン・アウト背景スライダー
 * @param {string | HTMLElement} jsClass クラス名
 * @param {boolean} loopActive ループさせるか
 * @param {number} speed フェードイン・アウトの時間
 * @param {number} delay スライドを表示する時間
 * @param {object} otherArgs その他設定項目
 * @returns
 */
export const swiperFadeSlideshow = (jsClass, loopActive = true, speed = 2000, delay = 3000, otherArgs = {}) => {
  const els = document.querySelectorAll(jsClass)
  if ((els?.length ?? 0) <= 0) { return; }

  els.forEach((el) => {
    const swiper = new Swiper(el, {
      modules: [Manipulation, Autoplay, EffectFade],
      loop: loopActive,
      speed,
      autoplay: {
        delay
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      allowTouchMove: false,
      ...otherArgs
    })

    // a11y対応時の一時停止ボタン実装用
    const swiperPlay = el.querySelector('.swiper-button-play')
    const swiperPause = el.querySelector('.swiper-button-pause')

    if (swiperPlay && swiperPause) {
      swiper.on('slideChange', () => {
        swiperPlay.disabled = true
        swiperPause.disabled = false
      })

      swiperPlay.addEventListener('click', () => {
        if (swiper.autoplay.paused) {
          swiperPlay.disabled = true
          swiperPause.disabled = false
          swiper.autoplay.run()
        }
      })

      swiperPause.addEventListener('click', () => {
        if (!swiperSlider.autoplay.paused) {
          swiperPause.disabled = true
          swiperPlay.disabled = false
          swiper.autoplay.pause()
        }
      })
    }
  })
}