import * as u from '../utility'
import {lanMenuToggle} from './languageSPToggle'

export default function hamburgerToggle() {
  if (!document.querySelector('.js-nav-hamburger')) { return; }
  const hamBtn         = document.querySelector('.js-nav-hamburger')
  const hamMenuArea    = document.querySelector('.js-nav-hamburger-menu-area')
  const hamBtnLabel    = hamBtn.querySelector('.label')

  hamburgerMenu(hamBtn, hamMenuArea, hamBtnLabel)
}

export const hamburgerMenu = (hamBtn, hamMenuArea, hamBtnLabel) => {
  const lanBtn         = document.querySelector('.js-nav-sp-language')
  const lanMenuArea    = document.querySelector('.js-nav-sp-language-menu-area')
  //ハンバーガーメニュー
  hamBtn.addEventListener('click', () => {
    if (!hamBtn.classList.contains('is-active')) {//ンバーガーメニューを開くとき
      if (lanBtn.classList.contains('is-active')) {//多言語メニューが開いていたら
        lanMenuToggle.close(lanBtn, lanMenuArea, hamMenuArea)//多言語メニューを閉じる
      }
      menuToggle.open(hamBtn, hamMenuArea, hamBtnLabel)
    } else {
      menuToggle.close(hamBtn, hamMenuArea, hamBtnLabel)
    }
  })

  //閉じる
  const closeBtn = document.querySelector('.js-nav-hamburger-close')
  closeBtn.addEventListener('click', () => {
    menuToggle.close(hamBtn, hamMenuArea, hamBtnLabel)
  })

  //ブレイクポイント切り替えでメニューを非表示にする
  const mql = window.matchMedia( u.headerBreakPC );
  const breakpointChange = (e) => {
    if (e.matches) {//PCレイアウトになったらハンバーガーメニューを閉じる
      menuToggle.close(hamBtn, hamMenuArea, hamBtnLabel)
    }
  }
  mql.addEventListener('change', breakpointChange);
}

export const menuToggle = {
  open: (btn, menuArea, label) => {
    btn.classList.add('is-active')
    btn.setAttribute('aria-label', 'メニューを閉じる')
    label.textContent = '閉じる'
    menuArea.classList.add('is-show')
    document.body.classList.add('is-non-scroll')
    document.querySelector('.l-header').classList.add('is-ham-menu-show')
  },
  close: (btn, menuArea, label) => {
    btn.classList.remove('is-active')
    btn.setAttribute('aria-label', 'メニューを開く')
    label.textContent = 'メニュー'
    menuArea.classList.remove('is-show')
    document.body.classList.remove('is-non-scroll')
    document.querySelector('.l-header').classList.remove('is-ham-menu-show')
  }
}