export default function innerLinkMovetoPage() {
  if (!document.querySelector('.l-header')) { return; }
  const headerHeight  = document.querySelector('.l-header').offsetHeight
  const hashurl       = location.hash
  const hash          = hashurl.slice(1);
  const target        = document.getElementById(hash);

  window.addEventListener('load', function() {
    if(target) {
      const top = target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
      setTimeout(function(){
        window.scrollTo({top: 0}, 0);
      });
      setTimeout(function(){
        window.scrollTo({
          top: top
        });
      });
    }
  })

}
