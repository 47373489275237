export default function popup() {
  popupCreate()
  popupClose()
}

export const popupCreate = () => {
  if (!document.querySelector('.js-popup')) { return; }
  const links = document.querySelectorAll('.js-popup')

  links.forEach(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      let thisLink = e.target
      if (thisLink.parentNode.classList.contains('js-popup')) {
        thisLink = thisLink.closest('.js-popup')
      }
      const url = thisLink.getAttribute('href')
      const params = 'width=840,height=600,menubar=yes,status=yes,scrollbars=yes';

      const detailWindow = window.open(url, 'detailWindow', params);
    })
  })
}

export const popupClose = () => {
  const closeBtns = document.querySelectorAll('.js-popup-close')
  closeBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      window.close()
    })
  })
}