import Swiper, { Autoplay, Navigation, Pagination } from '@/swiper';

export default function bannerSlider() {
  if (!document.querySelector('.js-banner-slider')) { return; }
  const swiper = new Swiper('.js-banner-slider', {
    modules: [Autoplay, Navigation, Pagination ],
    loop: true,
    slidesPerView: 1,
    // centeredSlides: true,
    spaceBetween: 12,
    speed: 800,
    pagination: {
      el: ".p-top-banner-slider-nav__pagination",
      clickable: true,
    },
    navigation: {
      nextEl: '.p-top-banner-slider-arrow__btn.-next',
      prevEl: '.p-top-banner-slider-arrow__btn.-prev',
    },
    grabCursor: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        spaceBetween: 12,
      },
      1280: {
        slidesPerView: 5,
        spaceBetween: 12,
      }
    },
  })

  const pauseBtn =  document.querySelector('.p-top-banner-slider-nav__pause')
  pauseBtn.addEventListener('click', function(){
    if (this.classList.contains('is-play')) {
      swiper.autoplay.start()
      this.classList.remove('is-play')
      this.textContent = '一時停止'
    } else {
      swiper.autoplay.stop()
      this.classList.add('is-play')
      this.textContent = '再生'
    }
  });

}

