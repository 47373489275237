import hamburgerToggle from './hamburgerToggle'
import languageDropMenu from './languageDropMenu'
import languageSPToggle from './languageSPToggle'
import megaMenu from './megaMenu'

export default function header() {
  hamburgerToggle()
  languageSPToggle()
  megaMenu()
  languageDropMenu()
}
