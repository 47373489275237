export default function tab() {
  if (!document.querySelector('.js-tab')) { return; }
  
  const tabBtns   = document.querySelectorAll('.js-tab__btn')
  const panels = document.querySelectorAll('.js-tab__panel')

  tabBtns.forEach(btn => {
    btn.addEventListener('click', (e) => {
      tabPanelToggle(e)
    })
  })

  const tabPanelToggle = (e) => {
    let thisBtn = e.target
    if (thisBtn.parentNode.classList.contains('js-tab__btn')) {
      thisBtn = thisBtn.closest('.js-tab__btn')
    }
    const panelId   = thisBtn.getAttribute('aria-controls')
    const thisPanel = document.getElementById(panelId)
    const parent    = thisBtn.closest('.js-tab')

    //タブボタン
    const thisTabBtns = parent.querySelectorAll('.js-tab__btn')
    thisTabBtns.forEach(btn => {
      btn.setAttribute('aria-selected', false)
      btn.classList.remove('-current')
    })
    thisBtn.setAttribute('aria-selected', true)
    thisBtn.classList.add('-current')

    //パネル表示
    const thisPanels = parent.querySelectorAll('.js-tab__panel')
    thisPanels.forEach(panel => {
      panel.setAttribute('aria-hidden', true)
    })
    thisPanel.setAttribute('aria-hidden', false)
  }
}