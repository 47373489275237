export default function routeMap() {
  if (!document.querySelector('.js-route-map')) { return; }
  
  const btns = document.querySelectorAll('.js-route-map-btn')
  const area = document.querySelector('.js-route-map')

  btns.forEach(btn => {
    btn.addEventListener('mouseover', (e) => {
      const thisBtn = e.target
      if (thisBtn.parentNode.classList.contains('js-route-map-btn')) {
        thisBtn = thisBtn.closest('.js-route-map-btn')
      }
      let id = thisBtn.dataset.mapId
      area.dataset.map = id
    })
  })

  document.addEventListener('mouseover', (e) => {
    const thisArea = e.target
    if (!thisArea.classList.contains('js-route-map-btn')) {
      if (!thisArea.parentNode.classList.contains('js-route-map-btn')) {
        area.dataset.map = 'all'
      }
    }
  })
}
