export default function languageDropMenu() {
  if (!document.querySelector('.js-language-drop')) { return; }
  const btn = document.querySelector('.js-language-drop')
  const menu = document.querySelector('.js-language-drop-menu-area')

  btn.addEventListener('click', () => {
    menu.classList.toggle('is-show')
  })

  //body clickで閉じる
  document.addEventListener('click', (e) => {
    if(!e.target.closest('.l-header-language')) {
      menu.classList.remove('is-show')
    }
  })
}