import * as u from '../utility'

export default function megaMenu() {
  const megaMenuBtns = document.querySelectorAll('.js-megamenu__btn')
  const megaMenuBox  = '.js-megamenu__box'
  const closeBtns    = document.querySelectorAll('.js-megamenu__close')

  /* -------------------------------------- 
  設定
  クリックでメガメニューが開く → click
  マウスオーバーでメガメニューが開く → mouseover
  */
  const mouse = 'click';
  /*
  補足：
  マウスオーバーかつ、ナビゲーションクリックでページ遷移をさせたい場合
  .js-megamenu__btn内の文字をspan.js-megamenu__linkで囲み
  data-page-linkに遷移先URLを指定する
  /* -------------------------------------- */

  megaMenuBtns.forEach(btn => {
    if (mouse === 'mouseover') {
      //マウスオーバーのとき
      btn.addEventListener('mouseover', (e) => {
        if (window.matchMedia( u.headerBreakPC ).matches) {//PCのとき
          toggle(e)
        }
      })
      //clickでページ遷移させるとき
      btn.addEventListener('click', (e) => {
        const thisBtn = e.target
        if(thisBtn.dataset.pageLink !== undefined) {
          location.href = thisBtn.dataset.pageLink
        }
        if (window.matchMedia( u.headerBreakSP ).matches) {//SPのとき
          toggle(e)
        }
      })
    } else {
      //clickのとき
      btn.addEventListener('click', (e) => {
        toggle(e)
      })
    }
  })

  //toggle呼び出し
  const toggle = (e) => {
    const btn = e.currentTarget
    if (btn.getAttribute('aria-expanded') === 'false') {
      //メニューを開く
      if (window.matchMedia( u.headerBreakPC ).matches) {//PCのとき
        navAreaToggle.closeAll()
      }
      navAreaToggle.open(e)
    } else {
      //メニューを閉じる
      if (mouse === 'mouseover') {
        if (window.matchMedia( u.headerBreakSP ).matches) {//SPのとき
          navAreaToggle.close(e)
        }
      } else {
        navAreaToggle.close(e)
      }
    }
  }

  //toggle
  const navAreaToggle = {
    open: (e) => {
      const btn = e.currentTarget
      const panelId = btn.getAttribute('aria-controls')
      btn.setAttribute('aria-expanded', 'true')
      btn.classList.add('is-active')
      document.getElementById(panelId).classList.add('is-show')
      e.stopPropagation()
    },
    close: (e) => {
      const btn = e.currentTarget;
      const panelId = btn.getAttribute('aria-controls')
      btn.setAttribute('aria-expanded', 'false')
      btn.classList.remove('is-active')
      document.getElementById(panelId).classList.remove('is-show')
      e.preventDefault()
    },
    closeAll: () => {
      megaMenuBtns.forEach(btn => {
        const panelId = btn.getAttribute('aria-controls')
        btn.setAttribute('aria-expanded', 'false')
        btn.classList.remove('is-active')
        document.getElementById(panelId).classList.remove('is-show')
      })
    }
  }

  const bodyEventClose = () => {
    if (window.matchMedia( u.headerBreakPC ).matches) {//PCのみ
      navAreaToggle.closeAll()
    }
  }

  closeBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      navAreaToggle.closeAll()
    })
  })

  if (mouse === 'mouseover') {
    //body mouseoverで閉じる
    document.addEventListener('mouseover', (e) => {
      if(!e.target.closest(megaMenuBox) && !e.target.closest('.js-megamenu__btn') && !e.target.classList.contains('.js-megamenu_btn')) {
        bodyEventClose()
      }
    })
  } else {
    //body clickで閉じる
    document.addEventListener('click', (e) => {
      if(!e.target.closest(megaMenuBox)) {
        bodyEventClose()
      }
    })
  }

  //ブレイクポイント切り替えでメニューを非表示にする
  const mql = window.matchMedia( u.headerBreakPC );
  const breakpointChange = (e) => {
    if (e.matches) {//PCレイアウトになったとき
      navAreaToggle.closeAll()
    } else {//SPレイアウトになったとき
      navAreaToggle.closeAll()
    }
  }
  mql.addEventListener('change', breakpointChange);
}